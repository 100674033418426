@import 'src/styles/variables';

.container {
  background-color: $blue-5;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    background-color: $white;
    top: 50%;
    width: 100%;
    height: 50%;
    position: absolute;
    z-index: -1;
    // background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1600 430' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.1 430L0 27.9C55.6 9.5 113.5.6 171.4.2c102.8-.8 203.2 22.7 299.3 54.5 3 1 5.9 2 8.9 3 183.6 62 365.7 146.1 562.4 192.1 186.7 43.7 376.3 34.4 557.9-12.6l.1 192.8H.1z' fill='%23fff' fillRule='nonzero'/%3E%3C/svg%3E");
  }

  &.whiteTop {
    background-color: $white;
  }
}

.box {
  max-width: $content-width;
  background: #0161e7;
  background: linear-gradient(to bottom right, #0161e7 0%, $blue-shadow 130%);
  padding: 100px 75px;
  margin: auto;
  z-index: 0;
  position: relative;
  overflow: hidden;

  @include for-size(desktop-down) {
    padding: 40px;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin: 0px auto;

  @include for-size(tablet-portrait-down) {
    padding-top: 10px;
  }

  @include for-size(phone-only) {
    justify-content: center;
  }

  .btnApply {
    @include for-size(tablet-landscape-down, 250px) {
      flex-wrap: wrap;
      margin: 5px 0;
    }
  }
}

.resumeImage {
  min-height: 200px;
}

.image {
  // Used in Generic CTA
  max-width: 480px;

  @include for-size(tablet-landscape-down) {
    max-width: 100%;
  }

  @include for-size(phone-only) {
    max-width: 100%;
    margin: 0px auto;
  }
}

.sectionCta {
  padding: 7px 24px;
  margin-left: 0px;
  min-width: 200px;
  text-transform: capitalize;

  @include for-size(phone-only) {
    margin-top: 20px;
  }
}

// For TalentCta
.browerFrame {
  // margin: 20px;
  border-top: 14px solid $gray-10;
  border-radius: 3px;
  box-shadow: $card-shadow2;
  position: relative;
  line-height: 0;
  // overflow: hidden;
}

.browserDot {
  position: absolute;
  top: -10px;
  left: 5px;
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $gray-40;
  z-index: $z-nav;

  & + & {
    left: 15px;
  }

  & + & + & {
    left: 25px;
  }
}

.wrapper {
  display: flex;
  align-items: center;

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5px 0;
  }
}

.imageContainer {
  flex-basis: 50%;
  width: 50%;

  .textContainer ~ & {
    padding-left: 20px;
  }

  @include for-size(tablet-landscape-down) {
    box-sizing: border-box;
    padding: 10px !important;
    flex-basis: 100%;
    width: 100%;
  }
}

.textContainer {
  flex-basis: 50%;
  width: 50%;
  flex-grow: 1;

  .imageContainer ~ & {
    padding-left: 20px;
  }

  @include for-size(tablet-landscape-down) {
    box-sizing: border-box;
    padding: 10px !important;
    flex-basis: 100%;
    width: 100%;
  }
}
